import { setItemStorage, getItemStorage, removeItemStorage } from "@/core/helpers/storage";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import store from "@/store";

class AuthService {
    /**
     * get user detail
     * @returns object
     */
    public static getUserDetail(): any {
        const userDetail: any = store.getters.getUserDetail; // || getItemStorage("usr");
        return userDetail;
    }

    /**
     * get user features
     * @returns object
     */
    public static getUserFeatures(): any {
        const user_features: any = store.getters.getUserFeatures;
        return user_features;
    }

    /**
     * get user properties list
     * @returns array
     */
    public static getUserPropertiesList(): any {
        const user_properties: any = store.getters.getUserPropertiesList;
        return user_properties;
    }

    /**
     * get selected user property value
     * @returns string
     */
    public static getUserProperty(): any {
        const userProperty: any = store.getters.getUserProperty; // || getItemStorage("usr_prop");
        return userProperty;
    }

    /**
     * set selected user propperty value
     */
    public static setUserProperty(value: any): void {
        store.commit(Mutations.SET_USER_PROPERTY, value);
        // setItemStorage('usr_prop', value);
    }

    /**
     * check if user can access the feature
     * @returns boolean
     */
    public static isUserFeature(feature_name: any, meta_name: any = ""): boolean {
        let is_feature = false;
        const user_features = store.getters.getUserFeatures;
        const have_feature_name = user_features ? user_features[feature_name] : null;
        if (have_feature_name) {
            if (meta_name) {
                const have_feature_meta = have_feature_name.includes(meta_name);
                is_feature = have_feature_meta;
            } else {
                is_feature = !!have_feature_name;
            }
        }

        return is_feature;
    }
}

export default AuthService;