import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setItemStorage, getItemStorage, removeItemStorage } from "@/core/helpers/storage";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { route } from '~ziggy-js';

export interface User {
  id: any;
  name: string;
  features: any;
  email: string;
  password: string;
  token: string;
  google2fa_is_active: boolean;
  is_active: boolean;
  properties: any;
  user_categories: any;
}

interface ListItemProperty {
  id: string;
  name: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: any; //User;
  user_property_id: string;
  userFeatures: any;
  userProperties: Array<ListItemProperty>;
  isAuthenticated: boolean;
  counter: number;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = getItemStorage("usr") || {};
  user_property_id = getItemStorage("usr_prop") || '';
  userFeatures = this.user['features'] || {};
  userProperties = this.user['properties'] as ListItemProperty[] || [];
  isAuthenticated = !!JwtService.getToken();
  counter = 0;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
 * Get user detail
 * @returns objects
 */
  get getUserDetail() {
    const userDetail: any = this.user || getItemStorage("usr");
    return userDetail;
  }

  /**
  * Get user features
  * @returns objects
  */
  get getUserFeatures() {
    return this.userFeatures;
  }

  /**
 * Get user properties list
 * @returns array
 */
  get getUserPropertiesList() {
    return this.userProperties;
  }

  /**
   * Get current user property
   * @returns string
   */
  get getUserProperty() {
    return this.user_property_id;
  }

  get getUserVerifyCounter() {
    return this.counter;
  }

  @Mutation
  ["setCounter"]() {
    this.counter++;
  }

  @Mutation
  [Mutations.SET_ERROR](error: any) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user: any) {
    this.isAuthenticated = true;
    this.user = user?.data;
    this.userFeatures = user?.data?.features || {};
    this.userProperties = user?.data?.properties as ListItemProperty[] || [];
    this.errors = {};
    setItemStorage('usr', user?.data);
    JwtService.saveToken(user?.token);
  }

  @Mutation
  [Mutations.SET_USER](user: any) {
    this.user = user;
    this.userFeatures = user?.features || {};
    this.userProperties = user?.properties as ListItemProperty[] || [];
    setItemStorage('usr', user);
  }

  @Mutation
  [Mutations.SET_USER_FEATURES](features: any) {
    this.userFeatures = features;
  }

  @Mutation
  [Mutations.SET_USER_PROPERTY](user_property_id: any) {
    setItemStorage('usr_prop', user_property_id);
    this.user_property_id = user_property_id;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user['password'] = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {};
    this.userFeatures = {};
    this.user_property_id = '';
    this.errors = [];
    JwtService.destroyToken();
    removeItemStorage('usr');
    removeItemStorage('usr_prop');
  }

  @Action
  [Actions.LOGIN](credentials: any) {
    return ApiService.post(route('auth.login'), credentials) // '/auth/gettoken'
      .then((response) => {
        // console.log(data);
        if (response.data) {
          this.context.commit(Mutations.SET_AUTH, response.data);
        }
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error?.data);
      });
  }

  @Action
  [Actions.AUTH_LOGIN](user: any) {
    this.context.commit(Mutations.SET_AUTH, user);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials: any) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error?.data);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload: any) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error?.data);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload: any) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(route('auth.verifytoken')) // '/auth/checkvalidtoken', payload
        .then((response) => {
          const resp = response.data;
          if (resp) {
            this.context.commit(Mutations.SET_USER, resp.data);
          }
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error?.data);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
